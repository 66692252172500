<template>
  <div class="recommendation content_box">
    <h3 class="text-24 leading-30 mx-24 pb-18 pt-24 border-b-1 border-b-solid border-b-[rgba(0,0,0,0.1)] flex-y-center justify-between">
      <span>{{ globalDataArray.siteTemplateLanguage.competition_recommendation_title }}</span>
      <button type="button" class="bg-transparent border-none flex-y-center gap-x-4" @click="refresh">
        <span class="i-icons:refresh inline-block size-16"></span>
        <span class="text-#ADADAD text-16 leading-22">{{ globalDataArray.siteTemplateLanguage.competition_recommendation_refresh }}</span>
      </button>
    </h3>
    <ul class="px-24">
      <li v-for="item in listing" :key="item.id">
        <h4 class="text-#292929 text-20 leading-30">
          <RouterLink :to="`/competitionInfo?id=${item.id}`" target="_blank" class="pl-0! text-inherit! color-inherit! hover:text-#1281FF!">
            {{ item.title }}
          </RouterLink>
        </h4>
        <div class="flex-y-center justify-between mt-8 gap-x-8">
          <div class="text-#ADADAD text-16 leading-24">{{ globalDataArray.siteTemplateLanguage.competition_recommendation_subtitle1 }}</div>
          <div class="bg-[linear-gradient(-82deg,#FF911E_0%,#EEB953_100%)] bg-clip-text text-transparent text-26 leading-30 text-right">{{ item.bonus }}</div>
        </div>
        <div class="flex-y-center justify-between mt-2 gap-x-8">
          <div class="text-#ADADAD text-16 leading-24">{{ globalDataArray.siteTemplateLanguage.competition_recommendation_subtitle2 }}</div>
          <div class="text-12 text-#ADADAD leading-24 text-right">{{ item.endTime }} {{ globalDataArray.siteTemplateLanguage.competition_recommendation_end }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getRecommendationList } from '@/api/saiti'

export default {
  name: 'SitiRecommendation',

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      listing: []
    }
  },

  mounted() {
    this.refresh()
  },

  methods: {
    async refresh() {
      const { success, result } = await getRecommendationList('competitionId=' + this.id)

      if (success) {
        this.listing = result
      }
    }
  }
}
</script>

<style scoped>
</style>
